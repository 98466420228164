import React from "react";
import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";
import {HashLink} from 'react-router-hash-link';
import {NavItem} from "./NavItem.d";
import {LanguageSelector} from "./LanguageSelector";
import {useTranslation} from "react-i18next";



export function Navbar() {
  const { t } = useTranslation();

  const navItems: NavItem[] = [
    {label: t('nav.what'), link: "what"},
    {label: t('nav.how'), link: "how"},
    {label: t('nav.who'), link: "who"},
    {label: t('nav.contact'), link: "footer"}
  ];

  return (
    <div className="fixed top-0 z-50 w-full text-gray-800 bg-white">
      <nav className="container flex z-20 justify-between items-center">
        <div className="my-5 mr-2 lg:my-6 flex items-center">
            <a href="/" className="mr-3">
              <img className="w-24 lg:w-36" src="/rentattest-logo.png" alt="rentattest logo"/>
            </a>
        </div>

        <div className="hidden lg:block text-sm text-neutral-grayish-blue">
          {navItems.map((navItem) => (
            <HashLink className="mx-3 py-5 hover:gradient-border-bottom hover:text-primary-cadet-blue font-medium"
                      to={`#${navItem.link}`}
                      key={navItem.link}
            >
              {navItem.label}
            </HashLink>
          ))}
        </div>
        <button className="hidden focus:outline-none">
          <img className="false" src="/icons/icon-hamburger.svg" alt=""/>
          <img className="hidden" src="/icons/icon-close.svg" alt=""/>
        </button>
        <LanguageSelector/>
      </nav>
    </div>
  )
}
