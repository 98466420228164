import {Link} from "react-router-dom";
import {PageNames} from "../pages/pages";
import React from "react";
import {useTranslation} from "react-i18next";

export function VraagSolvabiliteitsAttestAanButton() {
  const isActive = true;
  const { t } = useTranslation();
  let style = "py-3 px-7 text-xs rentattest-gradient rounded-md ring-primary-sky-blue lg:block " +
    "bg-primary-lime-green !text-neutral-white hover:button-brightness focus:outline-none focus:ring font-medium whitespace-nowrap";
  return isActive ? <Link
    className={style}
    to={PageNames.ITSME}>
    {t('requestAttest')}
  </Link> : null;
}
